import React, { memo } from 'react';
import { useDispatch } from 'react-redux';
import { Handle } from '@xyflow/react';
import contactsAPI from "../../../../api/contacts";
import ProfileIcon from '@mui/icons-material/AccountCircle';
import {Box, Card, CardActionArea, Typography} from '@mui/material';
import { useTarget } from '../../../../hooks/contacts/useTarget';

export default memo(({ data, isConnectable }) => {
  
  const dispatch = useDispatch();

  const target = useTarget();

  const bgColor = target == data.label ? '#fdd' : '#fff' ;

  const handleClick = () => {
    // FIXME: why only label - needs full data
    dispatch(contactsAPI.target(data.label));
  }
  
  return (
    <>
    <Handle
        type="target"
        position="left"
        style={{ background: 'gray' }}
        onConnect={(params) => console.log('handle onConnect', params)}
        isConnectable={isConnectable}
    />
    <Card sx={{p: 1, backgroundColor: bgColor}}>
      <CardActionArea onClick={handleClick}>
        <Box align='center'>
          <ProfileIcon/>
        </Box>
        <Typography align="center" variant="body2" sx={{ color: 'text.secondary' }}>
          {data.label}
        </Typography>
        </CardActionArea>
    </Card>
      <Handle
        type="source"
        position="right"
        id="a"
        style={{ background: 'gray' }}
        isConnectable={isConnectable}
      />
    </>
  );
});