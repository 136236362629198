import axiosInstance from "./setup/axiosInstance";
import ENDPOINTS from "./setup/constants";

const addRelationAPI = async (contactIdFrom, contactIdTo) => {
	return axiosInstance.post(
		ENDPOINTS.contact_relations_add,
		JSON.stringify({from: contactIdFrom, to: contactIdTo}),
		{
			headers: {
				'Content-Type': 'application/json'
			}
		}).then((res) => {
			return {...res.data, from: contactIdFrom, to: contactIdTo};
		});
};


const changeTarget = (targetId) => ({
	type: 'contacts/CHANGE_TARGET',
	target: targetId
})

const getContactAPI = async (contactId) => {
	return axiosInstance.post(
		ENDPOINTS.contact,
		JSON.stringify({id: contactId}),
		{
			headers: {
				'Content-Type': 'application/json'
			}
		}).then((res) => res.data);
};

const getContactRelationsAPI = async (contactId) => {
	return axiosInstance.post(
		ENDPOINTS.contact_relations,
		JSON.stringify({id: contactId}),
		{
			headers: {
				'Content-Type': 'application/json'
			}
		}).then((res) => ({ ... res.data, contactId: contactId}));
};

const contactsAPI = {
	contact: getContactAPI,
	relations: getContactRelationsAPI,
	addRelation: addRelationAPI,
	target: changeTarget
}

export default contactsAPI;
