import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {CALL_STATUS} from "./constants";
import contactsAPI from "../../api/contacts";
// import { enableMapSet } from 'immer';

// enableMapSet();

const initialState = {
	status: CALL_STATUS.IDLE,
	error: null,
	target: null,
	contacts: {},
	relations: []
}

export const fetchAddRelation = createAsyncThunk('contacts/FETCH_ADD_RELATION', async ({from, to}) => {
	console.log(from + " => " + to);
	const response = await contactsAPI.addRelation(from, to);
	return response;
});

export const fetchContact = createAsyncThunk('contacts/FETCH_CONTACT', async (contactId) => {
	const response = await contactsAPI.contact(contactId);
	return response;
});


export const fetchContactRelations = createAsyncThunk('contacts/FETCH_CONTACT_RELATIONS', async (contactId) => {
	const response = await contactsAPI.relations(contactId);
	return response;
});

const contactsSlice = createSlice({
	name: "contacts",
	initialState,
	reducers: {
		reset: initialState,
		CHANGE_TARGET: (state, action) => {
			state.status = CALL_STATUS.SUCCESS;
			state.error = null;
			state.target = action.target;
		}
	},
	extraReducers: (builder) => {
		builder
			.addCase(fetchAddRelation.pending, (state, action) => {
				state.status = CALL_STATUS.PENDING;
				state.error = null;
			})
			.addCase(fetchAddRelation.fulfilled, (state, action) => {
				state.status = CALL_STATUS.SUCCESS;
				state.error = null;
				const newRelation = {from: action.payload.from, to: action.payload.to};
				state.relations = [...state.relations, newRelation];	
			})
			.addCase(fetchAddRelation.rejected, (state, action) => {
				state.status = CALL_STATUS.ERROR;
				state.error = null;
			})
			.addCase(fetchContact.pending, (state, action) => {
				state.status = CALL_STATUS.PENDING;
				state.error = null;
			})
			.addCase(fetchContact.fulfilled, (state, action) => {
				state.status = CALL_STATUS.SUCCESS;
				state.error = null;
				state.target = action.payload.data.id;
				state.contacts = {... state.contacts, [action.payload.data.id] : {...action.payload.data, relations: false}};	
			})
			.addCase(fetchContact.rejected, (state, action) => {
				state.status = CALL_STATUS.ERROR;
				state.error = null;
			})
			.addCase(fetchContactRelations.pending, (state, action) => {
				state.status = CALL_STATUS.PENDING;
				state.error = null;				
			})
			.addCase(fetchContactRelations.fulfilled, (state, action) => {
				state.status = CALL_STATUS.SUCCESS;
				state.error = null;
				// why it called twice
				if(!state.contacts[action.payload.contactId].relations) {
					const requestedRelaitons = action.payload.data.contacts.map(c  => ({ from: action.payload.contactId, to: c.id }));
					const newRelaitons = [];
		
					for(var i in requestedRelaitons) {
						const requestedRelaiton = requestedRelaitons[i];

						var exists = false;
						for(var j in state.relations) {
							const stateRelation = state.relations[j];

							if((stateRelation.from == requestedRelaiton.from && stateRelation.to == requestedRelaiton.to) || 
								(stateRelation.to == requestedRelaiton.from && stateRelation.from == requestedRelaiton.to)) {
								exists = true;
								break;
							}
						}

						if(!exists) {
							newRelaitons.push(requestedRelaiton);
						}
					}

					for(var i in action.payload.data.relations) {
						const fGenContacts = action.payload.data.relations[i];
						for(var k in fGenContacts) {
							const fGenContact = fGenContacts[k];
							const newRelation = {from: i, to: fGenContact.id};
							
							var exists = false;
							for(var j in state.relations) {
								const stateRelation = state.relations[j];
	
								if((stateRelation.from == newRelation.from && stateRelation.to == newRelation.to) || 
									(stateRelation.to == newRelation.from && stateRelation.from == newRelation.to)) {
									exists = true;
									break;
								}
							}
							if(!exists) {
								for(var j in newRelaitons) {
									const stateRelation = newRelaitons[j];
		
									if((stateRelation.from == newRelation.from && stateRelation.to == newRelation.to) || 
										(stateRelation.to == newRelation.from && stateRelation.from == newRelation.to)) {
										exists = true;
										break;
									}
								}	
							}

							if(!exists) {
								newRelaitons.push(newRelation);
							}

						}
					}

					state.relations = [...state.relations, ...newRelaitons];	
					const newContacts = Object.fromEntries(action.payload.data.contacts.map(c => [c.id, c]));
					state.contacts = {...state.contacts, ...newContacts};	
					state.contacts[action.payload.contactId].relations = true;
				}
			})
			.addCase(fetchContactRelations.rejected, (state, action) => {
				state.status = CALL_STATUS.ERROR;
				state.error = null;
			})
	}
});

const {actions, reducer} = contactsSlice;

export const {
	reset
} = actions;

export default reducer;