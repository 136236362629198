import React from "react";
import {Navigate, Outlet, useLocation} from 'react-router-dom';
import {PATHS} from "../paths";
import {isAuthenticated} from "../../api/authStorage/isAuthenticated";

const PrivateRouteElement = ({children}) => {
	const location = useLocation();
	// console.log("Token in storage ", isAuthenticated());
	const authenticated = isAuthenticated();
	// console.log("Location pathname ", location.pathname);
	return (
		authenticated ? children : <Navigate to={PATHS.login} state={{from: location.pathname}}/>
	);
}

export default PrivateRouteElement;