const ENDPOINTS = {
	config_ui: '/api/v1/config',
	auth: '/api/v1/login',
	post: '/api/v1/posts/post',
	
	contact: '/api/v1/contact',
    contact_relations: '/api/v1/contact/first_gen_relations',
	contact_relations_add: '/api/v1/contact/relations/add'
}

export default ENDPOINTS;