import React, {useEffect, useState} from "react";
import {Button, TextField, Typography, Box} from "@mui/material";
import Grid from '@mui/material/Grid2';
import {useDispatch, useSelector} from "react-redux";
import Loading from "../../../common/loading/loading";
import {useNavigate, useLocation} from "react-router-dom";
import {PATHS} from '../../../router/paths';
import CommonPage from "../common/CommonPage";
import { fetchAuthToken } from "../../../store/reducers/auth.reducer";
import {CALL_STATUS} from "../../../store/reducers/constants";

const LoginPage = () => {

	const auth = useSelector(state => state.auth);

	const [credentials, setCredentials] = useState({
		login: '',
		password: ''
	});

	const dispatch = useDispatch();

	const onSubmit = (event) => {
		if (event)
			event.preventDefault();
		dispatch(fetchAuthToken(credentials));
	}

	const onChange = ({target: {name, value}}) => {
		setCredentials({...credentials, [name]: value})
	}

	let errorMsg;
	if (auth.status == CALL_STATUS.ERROR)
		errorMsg = auth.error || 'Wrong login or password';

	const location = useLocation();
	const navigate = useNavigate();

	useEffect(() => {
		if (auth.authenticated) {
			if (location.state && location.state.from) {
				navigate(location.state.from);
			} else {
				navigate(PATHS.cnvs);
			}
		}
	}, [auth.authenticated]);

	let loading = false;
	if(auth.status == CALL_STATUS.PENDING)
		loading = true;

	if (auth.authenticated) {
		return (
		<CommonPage>
			<Loading/>
		</CommonPage>
		);
	}

	return (
		<CommonPage sp maxWidth="xs">
			<Grid container justifyContent="center">
				<Grid size={6}>
					<Box sx={{ p: 2, border: '1px solid Gainsboro', borderRadius: '5px' }}>
			   {loading ? <Loading/> :
				<>
					{errorMsg &&
					<Grid container spacing={3}>
						<Grid size={12}>
							<Typography variant="h6" align="center" color="secondary">{errorMsg}</Typography>
						</Grid>
					</Grid>
					}
					<Grid container spacing={3}>
						<Grid size={12}>
							<Typography variant="h6" align="center" sx={{p: 1}}>Login</Typography>
						</Grid>
					</Grid>
					<form noValidate autoComplete="off" onSubmit={onSubmit}>
						<Grid container spacing={3}>
							<Grid size={12}>
								<Grid container spacing={2}>
									<Grid size={12}>
										<TextField
											id="login"
											name='login'
											fullWidth
											size='small'
											label="Login"
											variant="outlined"
											value={credentials.login}
											onChange={onChange}
										/>
									</Grid>
									<Grid size={12}>
										<TextField
											id="password"
											name='password'
											fullWidth
											size="small"
											label="Password"
											type="password"
											variant="outlined"
											value={credentials.password}
											onChange={onChange}
											autoComplete="on"
										/>
									</Grid>
								</Grid>
							</Grid>
							<Grid size={12}>
								<Button color="primary" fullWidth type="submit" variant="contained">
									Log in
								</Button>
							</Grid>
						</Grid>
					</form>
				</>
			}
			</Box>
			</Grid>
			</Grid>
		</CommonPage>
	);

};

export default LoginPage;