import React from 'react';
import {useContact} from '../../../../hooks/contacts/useContact';
import { useCallback } from 'react';

import {
  addEdge,
  ReactFlow,
  ReactFlowProvider,
  useNodesState,
  useEdgesState,
  Background,
  BackgroundVariant
} from '@xyflow/react';
 
import { mapCNVSTargetCentered } from './InitialElements';
import '@xyflow/react/dist/style.css';
 
import FloatingEdge from './FloatingEdge';
import ContactNode from './ContactNode';
import { useTarget } from '../../../../hooks/contacts/useTarget';
import { useDispatch } from 'react-redux';
import { fetchAddRelation } from '../../../../store/reducers/contacts.reducer';

const edgeTypes = {
  floating: FloatingEdge
};

const nodeTypes = {
  contactNode: ContactNode
};
 
const NodeAsHandleFlowInternal = () => {

  const dispatch = useDispatch();
  const target = useTarget();
  const cnvs = useContact();
  const { nodes: initialNodes, edges: initialEdges } = mapCNVSTargetCentered(cnvs, target);

  const [nodes, , onNodesChange] = useNodesState(initialNodes);
  const [edges, setEdges, onEdgesChange] = useEdgesState(initialEdges); 

  const onConnect = useCallback(
    (params) => {
      dispatch(fetchAddRelation({from: params.source, to: params.target}));
      //setEdges((eds) => addEdge({...params, type: 'floating'}, eds))
    },
    [],
  );
  
  return (
    <div style={{ height: 1000 }}>
    <ReactFlow
      nodes={nodes}
      edges={edges}
      onNodesChange={onNodesChange}
      onEdgesChange={onEdgesChange}
      onConnect={onConnect}
      edgeTypes={edgeTypes}
      nodeTypes={nodeTypes}
      fitView
    >
      <Background color="#eee" bgColor="#EFFFF5" variant={BackgroundVariant.Lines} />
    </ReactFlow>
    </div>
  );
};
 
export default function () {
  return (
    <ReactFlowProvider>
      <NodeAsHandleFlowInternal/>
    </ReactFlowProvider>
  );
}